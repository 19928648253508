import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { VercelService } from './services/vercel.service';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { isMonday, isSunday, isTuesday }from 'date-fns';
// import { DisabledTimeFn } from 'ng-zorro-antd/date-picker';
// import {IvyCarouselModule} from 'angular-responsive-carousel';

// import getISOWeek from 'date-fns/getISOWeek';
// import { en_US, NzI18nService, zh_CN } from 'ng-zorro-antd/i18n';


import Parse from 'parse';  //import Parse sdk with this sintax!


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit{
  p = 1;
  previousPage: number;
  categories: any = [];
  isCollapsed = false;
  loginForm!: FormGroup;
  registerForm!: FormGroup;
  forgotForm!: FormGroup;
  AddressForm!: FormGroup;
  contactForm!: FormGroup;
  loginVisible = false;
  registerVisible = false;
  forgotVisible = false;
  cartVisible = false;
  contactVisible = false;
  userLogged = false;
  userId = undefined;
  products: any = [];
  productsBackUp: any = [];
  productPagination: any = [];
  schedule: any = [];
  search = '';
  searching: boolean = false;
  CartData: any = [];
  CartLength: number;
  AddressData: any = [];
  Checkout = false;
  createAddress = false;
  creditCards = false;
  CardData: any = [];
  AddressID: string;
  CardID: string;
  note = false;
  additionalNote: string;
  delivery = false;
  AddCard = false;
  dateSelect: any;
  someTime: any;
  CardTypes = [
    'Visa',
    'MasterCard',
    'American Express',
    //'Discover',
    //'JCB',
    //'Diners Club',
  ];
  holderName: string;
  expDate: number;
  cardNumber: number;
  cvv: number;
  cardEmail: string;
  cardZipcode: string;
  SelectedCard: string;
  Note: string;
  couponCode: string;
  adding = false;
  zipCodeArr = [
    '00901',
    '00907',
    '00908',
    '00909',
    '00911',
    '00912',
    '00913',
    '00915',
    '00917',
    '00918',
    '00919',
    '00920',
    '00921',
    '00923',
    '00924',
    '00925',
    '00926',
    '00927',
    '00928',
    '00956',
    '00957',
    '00958',
    '00959',
    '00960',
    '00961',
    '00965',
    '00966',
    '00968',
    '00969',
    ,
    '00971',
    '00976',
    '00979',
    '00981',
    '00982',
    '00983',
    '00984',
    '00985',
    '00986',
    '00987',
    '00988',
  ];
  selectedZip: string;
  selectASAP = false;
  AsapChoice = false;
  DeliveryHr = false;
  today = new Date();
  message:string;
  isVisible = false;
  isVisible2 = false;
  isVisible3 = true;
  paymentTrue: boolean = false;
  Hours: string
  timeDefaultValue = new Date(0,0,0,12,0,0,0);
  showToday = false;
  loadOrder = false;
  savingCard = false;

  ads: any[] = []; //array of ads from back4app
  banners: any = [];
  carouselWidth: any = 0;
  carouselHeight: any = 0;
  cellWidth: any = 0;

  categoryProducts: any = [];
  allProducts: any = [];
  disableCheckout: boolean = false;
  messageGenerateOrder: string;
  messageASAP: string = `<p>Gracias por usar Ronpon.  Ya uno de nuestros drivers ha sido asignado para entregar su orden. Cualquier duda nos puede escribir o llamar al 787-223-6237.</p>

    <p>Si tiene alguna reclamación, llene el siguiente enlace: <br>
    <a href="https://forms.gle/qegetxaJ7jXpJapw8" target="_blank">https://forms.gle/qegetxaJ7jXpJapw8</a></p>
  
  <p>*Taggueanos en Instagram Story o Facebook con tu orden de Ronpon y te devolvemos el DELIVERY FEE</p>`;

  messageNormal: string = `<p>Gracias por usar Ronpon.  Su orden será entregada en el periodo de tiempo escogido. Cualquier duda nos puede escribir o llamar al 787-223-6237. </p>

    <p>Si tiene alguna reclamación, llene el siguiente enlace: <br>
    <a href="https://forms.gle/qegetxaJ7jXpJapw8" target="_blank">https://forms.gle/qegetxaJ7jXpJapw8</a></p>
  
  <p>*Taggueanos en Instagram Story o Facebook con tu orden de Ronpon y te devolvemos el DELIVERY FEE</p>`;
  isSpinning = false;

  Message: string;
  MessageBool = false;
  browserYear: Date = new Date();

  constructor(
    private api: VercelService,
    private fb: FormBuilder,
    public router: Router,
    private notification: NzNotificationService,
    private cdr: ChangeDetectorRef,
  ) {
    this.schedule = [
      //{ day: 'lunes', init: 'Cerrado', end: 'cerrado' },
      { day: 'Lunes', init: '10:00 AM', end: '10:00 PM' },
      { day: 'Martes', init: '10:00 AM', end: '10:00 PM' },
      { day: 'Miercoles', init: '10:00 AM', end: '12:00 AM' },
      { day: 'Jueves', init: '10:00 AM', end: '12:00 AM' },
      { day: 'Viernes', init: '10:00 AM', end: '12:00 AM' },
      { day: 'Sabado', init: '10:00 AM', end: '12:00 AM' },
      { day: 'Domingo', init: '10:00 AM', end: '08:00 PM' },
    ];
    api.getCategories().subscribe((data) => {
      this.categories = data;
    });
    this.loadProducts();
   
    // this.loadPopulares();

    //new for modal background color
    setTimeout(() => {
      const modal = document.querySelector('.ant-modal-content') as HTMLDivElement;
      if( modal ) {
        modal.style.backgroundColor = '#f8f8f8';
      }
    }, 500);
  }


  enablePayment(event){
      this.paymentTrue = true
      this.Hours = event.target.value
  }

  loadPopulares() {
    this.api.getPopulars().subscribe((data:any) => {
       
      for (const k in data) {
        const prod = data[k];
        
        prod.selected = false;
        this.products.push(prod);

      }
      
   
      // this.products = data;
      this.productsBackUp = this.products;
    });
  }

  loadProducts() {

    //get ads execute method
    this.getAds([], []);

    this.api.getProducts().subscribe((data) => {
     
      this.products = [];
      this.p = 1;
      window.scroll(0,0);
      for (const k in data) {
        const prod = data[k];
        prod.selected = false;
        this.products.push(prod);
       
    
      }
      // this.products = data;
      this.allProducts = this.products;
      this.productsBackUp = this.products;
    });
  }

  ngOnInit(): void {
 
    this.browserYear = new Date();
    
    this.userId = localStorage.getItem('userId');

    if (this.userId != undefined && this.userId != '') {
      this.GetCart();
      this.GetAddresses(this.userId);
    }

    this.loginForm = this.fb.group({
      userName: ['', [Validators.email, Validators.required]],
      password: ['', [Validators.required]],
    });

    this.AddressForm = this.fb.group({
      StreetAdd1: ['', [Validators.required]],
      StreetAdd2: ['', [Validators.required]],
      Ciudad: ['', [Validators.required]],
      ZipCode: ['', [Validators.required]],
    });

    this.registerForm = this.fb.group({
      email: [null, [Validators.email, Validators.required]],
      registerPassword: [null, [Validators.required]],
      checkRegisterPassword: [
        null,
        [Validators.required, this.confirmationValidator],
      ],
      name: [null, [Validators.required]],
      phoneNumber: [null, [Validators.required]],
    });

    this.forgotForm = this.fb.group({
      forgotEmail: ['', [Validators.email, Validators.required]],
    });

    this.contactForm = this.fb.group({
      contactEmail: ['', [Validators.email, Validators.required]],
      subject: ['', [Validators.required]],
      message: ['', [Validators.required]],
    });

    if (this.userId != undefined && this.userId != '') {
      this.userLogged = true;
    }
    
  }

  submitLoginForm(): void {
    for (const i in this.loginForm.controls) {
      this.loginForm.controls[i].markAsDirty();
      this.loginForm.controls[i].updateValueAndValidity();
    }

    const status = this.loginForm.status;

    if (status == 'VALID') {
      this.loginOk();
    }
  }

  submitAddress(): void {
    const StreetAdd1 = this.AddressForm.value.StreetAdd1;
    const StreetAdd2 = this.AddressForm.value.StreetAdd2;
    const Ciudad = this.AddressForm.value.Ciudad;
    const ZipCode = this.AddressForm.value.ZipCode;

    this.api
      .createAddress(this.userId, StreetAdd1, StreetAdd2, Ciudad, ZipCode)
      .subscribe((Data) => {
        this.GetAddresses(this.userId);
        this.closeCreateAddress();
      });
  }

  submitRegisterForm(): void {
    for (const i in this.registerForm.controls) {
      this.registerForm.controls[i].markAsDirty();
      this.registerForm.controls[i].updateValueAndValidity();
    }

    const email = this.registerForm.value.email;
    const password = this.registerForm.value.registerPassword;
    const phone = this.registerForm.value.phoneNumber;
    const name = this.registerForm.value.name;
    const status = this.registerForm.status;

    if (status == 'VALID') {
      this.api.signUp(email, password, phone, name).subscribe((data: any) => {
        if (data.error) {
          alert('Existe una cuenta con el correo electrónico suministrado');
        } else {
            this.api.login(email,password).subscribe((data:any) => {
                this.userLogged = true;
                this.userId = data.id;
                localStorage.setItem('userId', data.id);
                this.GetAddresses(this.userId);
                this.GetCart();
                this.loginVisible = false;
                alert('Bienvenido ' + data.name);
                this.closeLoginModals();
                alert('Exito! Su cuenta ha sido creada');
            })
        }
      });

      this.closeLoginModals();
    }
  }

  submitForgotForm(): void {
    for (const i in this.forgotForm.controls) {
      this.forgotForm.controls[i].markAsDirty();
      this.forgotForm.controls[i].updateValueAndValidity();
    }

    const email = this.forgotForm.value.forgotEmail;
    const status = this.forgotForm.status;

    if (status == 'VALID') {
      this.api.resetPassword(email).subscribe((data: any) => {
        if (data.success) {
          alert('Se ha enviado un correo a ' + email);
          this.closeLoginModals();
        } else if (data.error) {
          alert('No existe un usuario con el correo ' + email);
        }
      });
    }
  }

  submitContactForm(): void {
    for (const i in this.contactForm.controls) {
      this.contactForm.controls[i].markAsDirty();
      this.contactForm.controls[i].updateValueAndValidity();
    }

    const status = this.contactForm.status;
    const email = this.contactForm.value.contactEmail;
    const subject = this.contactForm.value.subject;
    const message = this.contactForm.value.message;

    if (status == 'VALID') {
      this.api.contact(email, subject, message).subscribe(() => {
        this.notification.create('success', 'Exito', 'Hemos recibido su mensaje');
        this.closeContactModal()
      })
    }
  }

  showLoginModal(): void {
    this.loginVisible = true;
    this.registerVisible = false;
    this.forgotVisible = false;
  }

  showCartModal(): void {
    this.GetCart();
      this.message = ''
    this.cartVisible = true;
  }

  showRegisterModal() {
    this.registerVisible = true;
    this.loginVisible = false;
    this.forgotVisible = false;
  }

  showForgotModal() {
    this.forgotVisible = true;
    this.loginVisible = false;
    this.registerVisible = false;
  }

  showContactModal() {
    this.contactVisible = true;
  }

  closeLoginModals() {
    this.loginVisible = false;
    this.registerVisible = false;
    this.forgotVisible = false;

    for (const i in this.loginForm.controls) {
      this.loginForm.controls[i].reset();
    }

    for (const i in this.registerForm.controls) {
      this.registerForm.controls[i].reset();
    }

    for (const i in this.forgotForm.controls) {
      this.forgotForm.controls[i].reset();
    }
  }

  closeContactModal() {
    this.contactVisible = false;
    this.contactForm.reset();
  }

  loginOk(): void {
    const user = this.loginForm.value.userName;
    const password = this.loginForm.value.password;

    if (
      user != undefined &&
      user != '' &&
      password != undefined &&
      password != ''
    ) {
      this.api.login(user, password).subscribe((data: any) => {
        if (data.error) {
          alert('Correo electrónico o contraseña incorrecta.');
        } else {
          this.userLogged = true;
          this.userId = data.id;
          localStorage.setItem('userId', data.id);
          this.GetAddresses(this.userId);
          this.GetCart();
          this.loginVisible = false;
          alert('Bienvenido ' + data.name);
          this.closeLoginModals();
        }
      });
    }
  }

  loginCancel(): void {
    this.loginVisible = false;
  }
  cartCancel(): void {
    this.cartVisible = false;
    this.Checkout = false;
    this.createAddress = false;
    this.creditCards = false;
    this.note = false;
    this.delivery = false;
    this.AddCard = false;
    this.paymentTrue = false;
    this.dateSelect = undefined;
  }

  resetPassword(email) {
    this.api.resetPassword(email);
  }

  updatePassword(token, password) {
    this.api.updatePassword(token, password);
  }

  logOut() {
    localStorage.removeItem('userId');
    this.userLogged = false;
    this.userId = undefined;
    this.router.navigate(['/welcome']);
  }

  productBySubcat(Catname) {
    //get ads by categories and subcategories array (get ads of this subcategory name)
    this.getAds([], [Catname]);

    this.api.getProductByCategory(Catname).subscribe((data) => {
      this.p = 1;
      this.search = undefined;
      this.searching = false;
      window.scroll(0,0);
      this.products = [];
      for (const k in data) {
        const prod = data[k];
        prod.selected = false;
        this.products.push(prod);
      }
      // this.products = data;
      this.productsBackUp = this.products;
      // this.products = data;
    });
    this.isCollapsed = true;
  }

  filterLocal() {
    // filtro de busqueda
    this.products = []; // limpio array para rellenarlo con la data filtrada
    if(this.search != undefined && this.search != '') {
      if (this.searching == false) {
        this.searching = true;
        this.categoryProducts = this.productsBackUp
        this.previousPage = this.p;
        this.p = 1
      } else {
        this.p = 1
      }
      // // bucle para buscar en cada string palabra por palabra
    this.allProducts.map((e, i) => {
      if (e.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) {
        // console.log('Busqueda true')
        this.products.push(this.allProducts[i]);
      }
    });
    // bucle para buscar en cada string palabra por palabra
    } else {
      // console.log('busqueda false')
      this.products = this.categoryProducts
      this.p = this.previousPage;
      this.searching = false;
    }
    
  }

  createNotification(status, title, body): void {
    this.notification.create(status, title, body);
  }

  AddCart(prodID, pos) {
      if(this.userId != null || this.userId != undefined){

        let exists = false;
        let quantity;
        this.products[pos].selected = true;
        this.CartData.products.some(data => {
          if (prodID == data.id) {
            exists = true;
            quantity = data.quantity + 1;
            return prodID === data.id;
          }
        });
        
        if (exists == false) {
          this.api.saveCart(prodID, this.userId).subscribe(
            (data: any) => {
              
      
              if (
                data.error !==
                'Product out of stock, cannot be added to your shopping cart'
              ) {
                this.CartData = data;
                this.products[pos].selected = false;
                this.createNotification(
                  'success',
                  'Correcto',
                  'Producto agregado al carrito'
                );
              } else {
                this.products[pos].selected = false;
                this.createNotification('error', 'Error', data.error);
              }
              this.GetCart();
            },
            (error) => {
              
              this.createNotification(
                'error',
                'Error',
                'Ocurrio un error mientras se agregaba al carrito'
              );
            }
          );
        } else if (exists == true) {
          this.api.updateCart(prodID, this.userId, quantity).subscribe((data:any) => {
            if (
              data.error !==
              'Product out of stock, cannot be added to your shopping cart'
            ) {
              this.CartData = data;
              this.products[pos].selected = false;
              this.createNotification(
                'success',
                'Correcto',
                'Aumentada cantidad del producto en el carrito'
              );
            } else {
              this.products[pos].selected = false;
              this.createNotification('error', 'Error', data.error);
            }
            this.GetCart();
          })
        }
        
      }else{
        this.isVisible = true;
        
      }

  }

  GetCart() {
    this.isSpinning = true;
    this.api.getCart(this.userId).subscribe((data: any) => {
      // console.log(data)
      this.CartLength = 0;
      let disableCheckout = false;
      if(data == null || data.products == null) return; //evit crash in for
      for (let prod of data.products) {
        this.CartLength += prod.quantity;
        if (prod.inStock == false) {
          disableCheckout = true;
          prod.available = "No disponible"
        } else {
          prod.available = "Disponible";
        }
      }
      if (disableCheckout == false) {
        this.disableCheckout = false;
      } else if (disableCheckout == true) {
        this.disableCheckout = true;
      }
      this.CartData = data;
      this.isSpinning = false;
    });
  }

  GetAddresses(userID) {
    this.api.getAddress(userID).subscribe((data: any) => {
      this.AddressData = data;
    });
  }

  Direction() {
    this.Checkout = true;
  }

  selectAddress(id) {
    this.AddressID = id;
    this.creditCards = true;
    this.Checkout = false;
    this.getCard();
  }

  getCard() {
    this.isSpinning = true;
    // Get Cards is not used any more. First Data
    // this.api.getCreditCard(this.userId).subscribe((data: any[]) => {
    //   this.CardData = data;
    //   this.isSpinning = false;
    //   console.log(data);
    // });

    //E-Wallet of Evertec. Only one card is allowed
    this.api.getUserInfo(this.userId).subscribe((data: any) => {
      this.CardData = data.wallets || [];
      this.isSpinning = false;
    });
  }

  selectCard(id) {
    this.CardID = id;

    this.creditCards = false;
    this.note = true;
  }

  CreateAddress() {
    this.createAddress = !this.createAddress;

    if (this.createAddress == false) {
      this.AddressForm.reset();
    }
  }

  closeCreateAddress() {
    this.createAddress = false;
    this.AddressForm.reset();
  }

  GoToPay() {
    this.note = false;
    this.delivery = true;
  }


  CreateCard() {
    this.AddCard = !this.AddCard;

    if (this.AddCard == false) {
      this.cardNumber = undefined;
      this.holderName = undefined;
      this.cvv = undefined;
      this.SelectedCard = undefined;
      this.expDate = undefined;
    }
  }

  generateCard() {
    this.api
      .createCreditCard(
        this.userId,
        this.cardNumber,
        this.holderName,
        this.cvv,
        this.SelectedCard,
        this.expDate
      )
      .subscribe((data) => {
        this.getCard();
        this.closeAddCard();
      });
  }

  saveEwallet() {
    if(this.savingCard) {
      //processing evit multiple executions, do nothing
      return;
    }
    this.savingCard = true;
    this.api
      .saveEwallet(
        this.userId,
        this.cardNumber,
        this.holderName,
        this.cvv,
        this.SelectedCard,
        this.expDate,
        this.cardEmail,
        this.cardZipcode,
      )
      .subscribe((data) => {
        this.savingCard = false;
        this.getCard();
        this.closeAddCard();
      });
  }

  closeAddCard() {
    this.cardNumber = undefined;
    this.holderName = undefined;
    this.cardEmail = undefined;
    this.cardZipcode = undefined;
    this.cvv = undefined;
    this.SelectedCard = undefined;
    this.expDate = undefined;
    this.AddCard = false;
  }

  /*GenerateOrder() { //not used anymore, using GenerateOrder2 a promise version for handling response when app is close
    this.loadOrder = true;
    this.isSpinning = true;
     
    //Note: createOrder replaced with createOrderWallet
    
    if (this.selectASAP) {
      const date = new Date( new Date().setMinutes(0,0,0) );
      this.messageGenerateOrder = this.messageASAP;
      if (
        this.Note != undefined ||
        this.Note != '' ||
        this.couponCode != undefined ||
        this.couponCode != ''
      ) {
        this.api.createOrderWallet(
          this.userId,
          //this.CardID,
          this.AddressID,
          date,
          this.Note,
          this.couponCode
        ).subscribe((data:any) => {
          console.log('Create Order Response 1: ', data);
            if(data.error){
                this.MessageBool = true;
                this.messageGenerateOrder = data.error
                this.isSpinning = false;
                this.loadOrder = false;
                this.isVisible2 = true;
                this.cartVisible = false;
                this.selectASAP = false;
            }else{
                this.isSpinning = false;
                this.loadOrder = false;
                this.isVisible2 = true;
                this.cartVisible = false;
                this.selectASAP = false;
                this.cartCancel(); //clear checkout modals
                this.DeleteAllCart();
            }
        
        });
      } else {
        this.api.createOrderWallet(
          this.userId,
          //this.CardID,
          this.AddressID,
          date,
          this.Note,
          this.couponCode
        ).subscribe((data:any) => {
            console.log('Create Order Response 2: ', data);
            if(data.error){
                this.MessageBool = true;
                this.messageGenerateOrder = data.error
                this.isSpinning = false;
                this.loadOrder = false;
                this.isVisible2 = true;
                this.cartVisible = false;
                this.selectASAP = false;
            }else{
                this.isSpinning = false;
                this.loadOrder = false;
                this.isVisible2 = true;
                this.cartVisible = false;
                this.selectASAP = false;
                this.cartCancel(); //clear checkout modals
                this.DeleteAllCart();
            }
        });
      }
    } else {
      this.messageGenerateOrder = this.messageNormal;
      if (
        this.Note != undefined ||
        this.Note != '' ||
        this.couponCode != undefined ||
        this.couponCode != ''
      ) {
        this.api.createOrderWallet(
          this.userId,
          //this.CardID,
          this.AddressID,
          this.dateSelect,
          this.Note,
          this.couponCode
        ).subscribe((data:any) => {
            console.log('Create Order Response 3: ', data);
            if(data.error){
                this.MessageBool = true;
                this.messageGenerateOrder = data.error
                this.isSpinning = false;
                this.loadOrder = false;
                this.isVisible2 = true;
                this.cartVisible = false;
                this.selectASAP = false;
            }else{
                this.isSpinning = false;
                this.loadOrder = false;
                this.isVisible2 = true;
                this.cartVisible = false;
                this.selectASAP = false;
                this.cartCancel(); //clear checkout modals
                this.DeleteAllCart();
            }
        });
      } else {
        this.api.createOrderWallet(
          this.userId,
          //this.CardID,
          this.AddressID,
          this.dateSelect,
          this.Note,
          this.couponCode
        ).subscribe((data:any) => {
            console.log('Create Order Response 4: ', data);
            if(data.error){
                this.MessageBool = true;
                this.messageGenerateOrder = data.error
                this.isSpinning = false;
                this.loadOrder = false;
                this.isVisible2 = true;
                this.cartVisible = false;
                this.selectASAP = false;
            }else{
                this.isSpinning = false;
                this.loadOrder = false;
                this.isVisible2 = true;
                this.cartVisible = false;
                this.selectASAP = false;
                this.cartCancel(); //clear checkout modals
                this.DeleteAllCart();
            }
        });
      }
    }
  }*/

  async GenerateOrder2() {
    try {
      this.loadOrder = true;
      this.isSpinning = true;
       
      //Note: createOrder replaced with createOrderWallet
      
      if (this.selectASAP) {
        const date = new Date( new Date().setMinutes(0,0,0) );
        this.messageGenerateOrder = this.messageASAP;
        if (
          this.Note != undefined ||
          this.Note != '' ||
          this.couponCode != undefined ||
          this.couponCode != ''
        ) {
          const data: any = await this.api.createOrderWalletPromise(
            this.userId,
            //this.CardID,
            this.AddressID,
            date,
            this.Note,
            this.couponCode
          );
          console.log('Create Order Response 1: ', data);
              if(data.error){
                  this.MessageBool = true;
                  this.messageGenerateOrder = data.error
                  this.isSpinning = false;
                  this.loadOrder = false;
                  this.isVisible2 = true;
                  this.cartVisible = false;
                  //this.selectASAP = false;
              }else{
                  this.isSpinning = false;
                  this.loadOrder = false;
                  this.isVisible2 = true;
                  this.cartVisible = false;
                  this.selectASAP = false;
                  this.cartCancel(); //clear checkout modals
                  this.DeleteAllCart();
              }
        } else {
          const data: any = await this.api.createOrderWalletPromise(
            this.userId,
            //this.CardID,
            this.AddressID,
            date,
            this.Note,
            this.couponCode
          );
          console.log('Create Order Response 2: ', data);
              if(data.error){
                  this.MessageBool = true;
                  this.messageGenerateOrder = data.error
                  this.isSpinning = false;
                  this.loadOrder = false;
                  this.isVisible2 = true;
                  this.cartVisible = false;
                  //this.selectASAP = false;
              }else{
                  this.isSpinning = false;
                  this.loadOrder = false;
                  this.isVisible2 = true;
                  this.cartVisible = false;
                  this.selectASAP = false;
                  this.cartCancel(); //clear checkout modals
                  this.DeleteAllCart();
              }
        }
      } else {
        this.messageGenerateOrder = this.messageNormal;
        if (
          this.Note != undefined ||
          this.Note != '' ||
          this.couponCode != undefined ||
          this.couponCode != ''
        ) {
          const data:any = await this.api.createOrderWalletPromise(
            this.userId,
            //this.CardID,
            this.AddressID,
            this.dateSelect,
            this.Note,
            this.couponCode
          );
          console.log('Create Order Response 3: ', data);
              if(data.error){
                  this.MessageBool = true;
                  this.messageGenerateOrder = data.error
                  this.isSpinning = false;
                  this.loadOrder = false;
                  this.isVisible2 = true;
                  this.cartVisible = false;
                  this.selectASAP = false;
              }else{
                  this.isSpinning = false;
                  this.loadOrder = false;
                  this.isVisible2 = true;
                  this.cartVisible = false;
                  this.selectASAP = false;
                  this.cartCancel(); //clear checkout modals
                  this.DeleteAllCart();
              }
        } else {
          const data: any = await this.api.createOrderWalletPromise(
            this.userId,
            //this.CardID,
            this.AddressID,
            this.dateSelect,
            this.Note,
            this.couponCode
          );
          console.log('Create Order Response 4: ', data);
              if(data.error){
                  this.MessageBool = true;
                  this.messageGenerateOrder = data.error;
                  this.isSpinning = false;
                  this.loadOrder = false;
                  this.isVisible2 = true;
                  this.cartVisible = false;
                  //this.selectASAP = false;
              }else{
                  this.isSpinning = false;
                  this.loadOrder = false;
                  this.isVisible2 = true;
                  this.cartVisible = false;
                  this.selectASAP = false;
                  this.cartCancel(); //clear checkout modals
                  this.DeleteAllCart();
              }
        }
      }
    }
    catch(error) {
      //error
      console.log('Create Order Error: ', error);
      this.MessageBool = true;
      this.messageGenerateOrder = (error) ? error.error : error;
      this.isSpinning = false;
      this.loadOrder = false;
      this.isVisible2 = true;
      this.cartVisible = false;
      this.selectASAP = false;
      alert('Catch error');
    }
    
  }

  async GenerateOrder3() {
    try {
      this.loadOrder = true;
      this.isSpinning = true;
       
      //Note: createOrder replaced with createOrderWallet
      
      if (this.selectASAP) {
        const date = new Date( new Date().setMinutes(0,0,0) );
        this.messageGenerateOrder = this.messageASAP;
        if (
          this.Note != undefined ||
          this.Note != '' ||
          this.couponCode != undefined ||
          this.couponCode != ''
        ) {
          const data: any = await this.api.createOrderNew(
            this.userId,
            this.CardID,
            this.AddressID,
            date,
            this.Note,
            this.couponCode
          );
          console.log('Create Order Response 1: ', data);
              if(data.error){
                  this.MessageBool = true;
                  this.messageGenerateOrder = data.error
                  this.isSpinning = false;
                  this.loadOrder = false;
                  this.isVisible2 = true;
                  this.cartVisible = false;
                  //this.selectASAP = false;
              }else{
                  this.isSpinning = false;
                  this.loadOrder = false;
                  this.isVisible2 = true;
                  this.cartVisible = false;
                  this.selectASAP = false;
                  this.cartCancel(); //clear checkout modals
                  this.DeleteAllCart();
              }
        } else {
          const data: any = await this.api.createOrderNew(
            this.userId,
            this.CardID,
            this.AddressID,
            date,
            this.Note,
            this.couponCode
          );
          console.log('Create Order Response 2: ', data);
              if(data.error){
                  this.MessageBool = true;
                  this.messageGenerateOrder = data.error
                  this.isSpinning = false;
                  this.loadOrder = false;
                  this.isVisible2 = true;
                  this.cartVisible = false;
                  //this.selectASAP = false;
              }else{
                  this.isSpinning = false;
                  this.loadOrder = false;
                  this.isVisible2 = true;
                  this.cartVisible = false;
                  this.selectASAP = false;
                  this.cartCancel(); //clear checkout modals
                  this.DeleteAllCart();
              }
        }
      } else {
        this.messageGenerateOrder = this.messageNormal;
        if (
          this.Note != undefined ||
          this.Note != '' ||
          this.couponCode != undefined ||
          this.couponCode != ''
        ) {
          const data:any = await this.api.createOrderNew(
            this.userId,
            this.CardID,
            this.AddressID,
            this.dateSelect,
            this.Note,
            this.couponCode
          );
          console.log({
            userId: this.userId,
            cardId: this.CardID,
            addressId: this.AddressID,
            date: this.dateSelect,
            note: this.Note,
            coupon: this.couponCode
          });
          console.log('Create Order Response 3: ', data);
              if(data.error){
                  this.MessageBool = true;
                  this.messageGenerateOrder = data.error
                  this.isSpinning = false;
                  this.loadOrder = false;
                  this.isVisible2 = true;
                  this.cartVisible = false;
                  this.selectASAP = false;
              }else{
                  this.isSpinning = false;
                  this.loadOrder = false;
                  this.isVisible2 = true;
                  this.cartVisible = false;
                  this.selectASAP = false;
                  this.cartCancel(); //clear checkout modals
                  this.DeleteAllCart();
              }
        } else {
          const data: any = await this.api.createOrderNew(
            this.userId,
            this.CardID,
            this.AddressID,
            this.dateSelect,
            this.Note,
            this.couponCode
          );
          console.log('Create Order Response 4: ', data);
              if(data.error){
                  this.MessageBool = true;
                  this.messageGenerateOrder = data.error;
                  this.isSpinning = false;
                  this.loadOrder = false;
                  this.isVisible2 = true;
                  this.cartVisible = false;
                  //this.selectASAP = false;
              }else{
                  this.isSpinning = false;
                  this.loadOrder = false;
                  this.isVisible2 = true;
                  this.cartVisible = false;
                  this.selectASAP = false;
                  this.cartCancel(); //clear checkout modals
                  this.DeleteAllCart();
              }
        }
      }
    }
    catch(error) {
      //error
      console.log('Create Order Error: ', error);
      this.MessageBool = true;
      this.messageGenerateOrder = (error) ? error.error : error;
      this.isSpinning = false;
      this.loadOrder = false;
      this.isVisible2 = true;
      this.cartVisible = false;
      this.selectASAP = false;
      alert('Catch error');
    }
    
  }

  BuyASAP() {
    this.selectASAP = true;
    this.paymentTrue = true;
    // const date = new Date();

    // if (
    //   this.Note != undefined ||
    //   this.Note != '' ||
    //   this.couponCode != undefined ||
    //   this.couponCode != ''
    // ) {
    //   this.api
    //     .createOrder(
    //       this.userId,
    //       this.CardID,
    //       this.AddressID,
    //       date,
    //       this.Note,
    //       this.couponCode
    //     )
    //     .subscribe((data) => {
    //       console.log(data);
    //     });
    // } else {
    //   this.api
    //     .createOrder(
    //       this.userId,
    //       this.CardID,
    //       this.AddressID,
    //       date,
    //       this.Note,
    //       this.couponCode
    //     )
    //     .subscribe((data) => {
    //       console.log(data);
    //     });
    // }
  }

  checkCoupon() {
      
    if (this.couponCode != '' || this.couponCode != undefined) {
      this.api.VerifyCoupon(this.couponCode, this.userId,this.CartData.subTotal);
      this.api.currentMessage.subscribe(message =>{ 
          this.message = message;
          if(this.message == "Se aplico el cupon a su orden!" ){
            //actualiza el delivery fee
            this.CartData.fee = 0;
            if( this.CartData.subTax == null ) { this.CartData.total = this.CartData.total - 5.99; }
            else {
              this.CartData.tax = this.CartData.subTax;
              this.CartData.total = Math.round( (this.CartData.subTotal + this.CartData.subTax) * 100 ) / 100;
            }
          }
        })
    
    }

  }

  updateConfirmValidator(): void {
    /** wait for refresh value */
    Promise.resolve().then(() =>
      this.registerForm.controls.checkRegisterPassword.updateValueAndValidity()
    );
  }

  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (
      control.value !== this.registerForm.controls.registerPassword.value
    ) {
      return { confirm: true, error: true };
    }
    return {};
  };

  checkAsapHour() {
    const date = new Date();
    //date.setHours( (24 * 6) + 23 );
    const Day = date.getDay();
    const Hour = date.getHours();
    const buyASAP = document.getElementById('buyASAP');

    //console.log("ASAP Date now: ", date, Day, Hour);

    //check asap is renderer if null the do nothing -- evit crash
    if(buyASAP == null) return;

    if (Day == 6 && !(Hour >= 10 && Hour <= 23)) { //sabado
      this.DeliveryHr = true;
      buyASAP.style.color = '#666666';
      buyASAP.style.backgroundColor = '#cccccc';
      buyASAP.style.pointerEvents = 'none';
    }else if (Day == 0 && !(Hour >= 10 && Hour < 20)) { //domingo
        this.DeliveryHr = true;
        buyASAP.style.color = '#666666';
        buyASAP.style.backgroundColor = '#cccccc';
        buyASAP.style.pointerEvents = 'none';
      } 
      //lunes old
    /*else if (Day == 1) {
      this.DeliveryHr = true;
      buyASAP.style.color = '#666666';
      buyASAP.style.backgroundColor = '#cccccc';
      buyASAP.style.pointerEvents = 'none';
    }*/ 
    else if (Day == 1 && !(Hour >= 10 && Hour < 22)) { //lunes
      this.DeliveryHr = true;
      buyASAP.style.color = '#666666';
      buyASAP.style.backgroundColor = '#cccccc';
      buyASAP.style.pointerEvents = 'none';
    }
    else if (Day == 2 && !(Hour >= 10 && Hour < 22)) { //martes
      this.DeliveryHr = true;
      buyASAP.style.color = '#666666';
      buyASAP.style.backgroundColor = '#cccccc';
      buyASAP.style.pointerEvents = 'none';
    } else if (Day == 3 && !(Hour >= 10 && Hour <= 23)) { //miercoles
      this.DeliveryHr = true;
      buyASAP.style.color = '#666666';
      buyASAP.style.backgroundColor = '#cccccc';
      buyASAP.style.pointerEvents = 'none';
    } else if (Day == 4 && !(Hour >= 10 && Hour <= 23)) { //jueves
      this.DeliveryHr = true;
      buyASAP.style.color = '#666666';
      buyASAP.style.backgroundColor = '#cccccc';
      buyASAP.style.pointerEvents = 'none';
    } else if (Day == 5 && !(Hour >= 10 && Hour <= 23)) { //viernes
      this.DeliveryHr = true;
      buyASAP.style.color = '#666666';
      buyASAP.style.backgroundColor = '#cccccc';
      buyASAP.style.pointerEvents = 'none';
    }
    else {
      
    }
  }
  UpdateCart(prodID, event: any) {
    const QTY = parseInt(event.target.value);
    this.api.updateCart(prodID, this.userId, QTY).subscribe((data) => {
      this.GetCart();
    });
  }
  DeleteCart(prodID) {
    this.api.getCart(this.userId).subscribe((data: any) => {
      const products = data.products;
      for (let i = 0; i < products.length; i++) {
        if (prodID == products[i].id) {
          this.api.deleteCart(prodID, this.userId, 0).subscribe(() => {
            this.GetCart();
          });
        }
      }
    });
  }

  DeleteAllCart() {
    this.api.deleteAllCart(this.userId).subscribe(() => {
      this.GetCart();
    })
  }

  noteBack() {
    this.note = false;
    this.creditCards = true;
  }

  AddressBack() {
    this.createAddress = false;
    this.Checkout = false;
  }

  CardBack() {
    this.creditCards = false;
    this.Checkout = true;
  }

  DeliveryOptions() {
    this.delivery = false;
    this.note = true;
    this.paymentTrue = false;
    this.dateSelect = undefined;
  }

  range(start: number, end: number): number[] {
    const result: number[] = [];
    for (let i = start; i <= end; i++) {
      result.push(i);
    }
    return result;
  }
  
  disabledDate = (current: Date): boolean => {
    // Can not select days before today and today
    const beforeToday = differenceInCalendarDays(current, this.today) < 0;
    //const monday = isMonday(current);
    this.disabledDateTime;

    //return beforeToday || monday;
    return beforeToday;
  };

  disabledDateTime = (current: Date) => {
    const date = new Date();
    return {
      nzDisabledHours: () => {
        if (current != undefined) {
          if (current.getDate() == date.getDate()) {
            if (isSunday(current)) {
              let range = this.range(0, date.getHours());
              let range2 = this.range(21,23);
  
              range2.forEach(element => {
                range.push(element);
              });
              return range
            } else if (isTuesday(current)) {
              let range = this.range(0, date.getHours());
              range.push(23);
              
              return range
            } else {
              let range = this.range(1, date.getHours());

              return range
            }
          } else if (isSunday(current)) {
            let range = this.range(0, 9);
            let range2 = [21,22,23];

            range2.forEach(element => {
              range.push(element);
            });

            return range
          } else if (isTuesday(current)) {
            let range = this.range(0, 9);
            range.push(23);
            
            return range
          } else {
            let range = this.range(1, 9);

            return range
          }
        } else {
          let range = this.range(1, 9);

          return range
        }
      },
      nzDisabledMinutes: () => [],
      nzDisabledSeconds: () => []
    };    
  };

  getZeroDate() {
    let date = this.dateSelect.setMinutes(0,0,0);
    this.dateSelect = new Date(date).toISOString();
  }

  handleOk(): void {
    
    this.isVisible = false;
  }

  handleOk2(): void {
    
    this.isVisible2 = false;
  }
  handleOk3(): void {
    
    this.isVisible3 = false;
  }

  cancelOK() : void {
    this.isVisible = false;
  }

  changePage(event) {
    this.p = event;
    window.scroll(0,0);
  }


  //Toggle Menu (when user click menu icon to collapse menu)
  toggleMenu() {
    this.isCollapsed = !this.isCollapsed;
    let temps = this.ads.concat([]);
    this.ads = [];
    setTimeout(() => {
      this.ads = temps;
      // this.setCarouselOptions();
    }, 1000);
  }

  //ad clicked by user
  adClick(ad) {
    // console.log("Banner Clicked!!! ", ad);
    if(ad.get('link') != null) {
      window.open(ad.get('link'), '_blank');
      // console.log('blank')
    }
    else if( ad.get('productId') != null ) {
      // console.log('product')
      //ad is asociated with a product so add this product to shopping cart if user is logged!
    }
  }

  //get ads from back4app
  async getAds(categories: any[] = [], subcategories: any[] = []) {

    //clear ads
    this.ads = [];
    // this.setCarouselOptions();

    //fecth new ads
    
    const params = {
        categories: categories,
        subcategories: subcategories,
        platform: "web"
    };
    // console.log("get Ads params: ");
    // console.log(params);

    Parse.initialize("SUGY240pPSoZYwMHMvOrAOA4pfwQTkAiVZ6FkQDq", "Uz9iOWKIVr3PV1DeZa3RScBpiveN03iXt8KmM7KA");
    Parse.serverUrl = "https://parseapi.back4app.com/";
    Parse.serverURL = "https://parseapi.back4app.com/";
    Parse.Cloud.run("getAds", params).then((results) => {
      //success
      //this.setCarouselOptions();
      setTimeout(() => {
        this.ads = results;
        // this.setCarouselOptions();
      }, 1000);
      // console.log("getAds results: ", results);
    }, (error) => {
      //an error occur
      // console.log("Error in getAds ----> ", error);
    });
  }

  // async setCarouselOptions() {

  //   if( this.ads.length === 0 ) {
  //     console.log("no ads hide carousel")
  //     this.carouselWidth = 0;
  //     this.carouselHeight = 0;
  //     this.cellWidth = 0;
  //     return;
  //   }

  //   let searchContainer = document.getElementById('search-container');
  //   if(searchContainer == null) return;
  //   let rect = searchContainer.getBoundingClientRect();

  //   console.log(rect);

  //   this.carouselWidth = rect.width;
  //   this.carouselHeight = this.detectMobile() ? 80 : 200;
  //   this.cellWidth = rect.width;

  //   console.log("carousel setup dimension updated!");

  //   // this.cdr.detectChanges();
  // }

  detectMobile() {
    //device less than 800 X 600 can be considered a mobile
    return ( ( window.innerWidth <= 800 ) || ( window.screen.width <= 800 ) );
  }

  
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/welcome' },
  {
    path: 'welcome',
    loadChildren: () =>
      import('./pages/welcome/welcome.module').then((m) => m.WelcomeModule),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./pages/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'titosviewingparty',
    loadChildren: () =>
      import('./pages/titosviewingparty/titosviewingparty.module').then((m) => m.TitosviewingpartyModule),
  },
  {
    path: 'terms-privacy',
    loadChildren: () =>
      import('./pages/terms-privacy/terms-privacy.module').then((m) => m.TermsPrivacyModule),
  },
  {
    path: 'tos',
    loadChildren: () =>
      import('./pages/terms-privacy/terms-privacy.module').then((m) => m.TermsPrivacyModule),
  },
  {
    path: 'reset-password/token/:token',
    loadChildren: () =>
      import('./pages/forgotpassword/forgotpassword.module').then((m) => m.ForgotpasswordModule),
  },
  {
    path: 'fbdeletion',
    loadChildren: () =>
      import('./pages/fbdeletion/fbdeletion.module').then((m) => m.FbdeletionModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VercelService {
  baseUrl: string;
  message: string;
 
  
  private messageSource = new BehaviorSubject('Verificando...');
  currentMessage = this.messageSource.asObservable();

  constructor(private http: HttpClient) {
    this.baseUrl = 'https://ronpon-prisma.vercel.app/api/';
  }

  getCategories() {
    return this.http.post(this.baseUrl + 'getCategories', {});
  }

  login(email, password) {
    return this.http.post(this.baseUrl + 'login', {
      email,
      password,
    });
  }

  signUp(email, password, phone, name) {
    return this.http.post(this.baseUrl + 'signup', {
      email,
      password,
      phone,
      name,
    });
  }

  resetPassword(email) {
    return this.http.post(this.baseUrl + 'resetPassword', {
      email,
    });
  }

  updatePassword(token, password) {
    return this.http.post(this.baseUrl + 'updatePassword', {
      resetToken: token,
      password,
    });
  }

  getUserInfo(id) {
    return this.http.post(this.baseUrl + 'user', {
      id,
    });
  }

  getUserAddresses(userId) {
    return this.http.post(this.baseUrl + 'addresses', {
      id: userId,
    });
  }

  getUserCreditCards(userId) {
    return this.http.post(this.baseUrl + 'creditCards', {
      id: userId,
    });
  }

  deleteUserAddres(addresId) {
    return this.http.post(this.baseUrl + 'deleteAddress', {
      id: addresId,
    });
  }
  deleteUserCreditCard(cardId, userId) {
    return this.http.post(this.baseUrl + 'deleteCreditCard', {
      id: cardId,
      userId: userId,
    });
  }

  getUserOders(userId) {
    return this.http.post(this.baseUrl + 'orders', {
      userId: userId,
    });
  }
  getProducts() {
    return this.http.post(this.baseUrl + 'products', {});
  }

  getProductByCategory(name) {
    return this.http.post(this.baseUrl + 'products', { subcategory: name });
  }

  saveCart(prodID, userID) {
    return this.http.post(this.baseUrl + 'saveCart', {
      userId: userID,
      product: { id: prodID, quantity: 1 },
    });
  }

  updateCart(prodID,userID, QTY){
    return this.http.post(this.baseUrl + 'saveCart', {
        userId: userID,
        product: { id: prodID, quantity: QTY },
      });
  }

  deleteCart(prodID, userID, QTY){
    return this.http.post(this.baseUrl + 'saveCart', {
        userId: userID,
        product: { id: prodID, quantity: QTY },
      });
  }

  deleteAllCart(userID){
    return this.http.post(this.baseUrl + 'saveCart', {
        userId: userID,
        products: [],
      });
  }

  getCart(userID) {
    return this.http.post(this.baseUrl + 'getCart', { userId: userID });
  }

  getPopulars(){
      return this.http.post(this.baseUrl + 'populars', {});
  }

  getAddress(userID){
   
    return this.http.post(this.baseUrl + 'addresses', { id: userID });
  }

  createAddress(userID,StreetAdd1,StreetAdd2,Ciudad,Zipcode){
    return this.http.post(this.baseUrl + 'createAddress', { userId: userID,address1 : StreetAdd1,address2: StreetAdd2,
                                                         city: Ciudad,zipCode: Zipcode});
    
  }

  getCreditCard(userID){
    return this.http.post(this.baseUrl + 'creditCards', { id: userID });
  }

  createCreditCard(userID, CardNumber, HolderName,cvv,type,expDate){
    return this.http.post(this.baseUrl + 'createCreditCard', { cardNumber: CardNumber,cardHolderName: HolderName,cvv: cvv,
        type:type,expDate: expDate, userId: userID });
  }

  saveEwallet(userID, CardNumber, HolderName, cvv, type, expDate, cardEmail, cardZipcode) {
    let firstName = '', lastName = '';
    let names = HolderName.split(' ') || [];
    firstName = (names.length != 0) ? names[0].trim() : '';
    for(let i = 1; i < names.length; i++) {
      lastName = ( lastName + ' ' + names[i].trim() ).trim();
    }
    return this.http.post(this.baseUrl + 'saveEwallet', { cardNumber: CardNumber,firstName: firstName, lastName: lastName,
       email: cardEmail, cardCVV: cvv, type:type, cardExpirationDate: expDate, zipCode: cardZipcode, userId: userID });
  }


  //not used any more - First Data
  createOrder(userId,cardId,addressId,date,note,couponCode){
    return this.http.post(this.baseUrl + 'createOrder', {userId: userId, cardId:cardId,addressId:addressId,
                                                         date:date,note:note,couponCode:couponCode})
   }

   //payment with evertec
   createOrderNew(userId,cardId,addressId,date,note,couponCode){
    return this.http.post(this.baseUrl + 'createOrderNew', {userId: userId, cardId:cardId,addressId:addressId,
                                                         date:date,note:note,couponCode:couponCode}).toPromise();
   }

   //Pay with E-Wallet Evertec and create order
   createOrderWallet(userId,addressId,date,note,couponCode){
    return this.http.post(this.baseUrl + 'createOrderWallet', {userId: userId,addressId:addressId,
                                                         date:date,note:note,couponCode:couponCode})
   }

   createOrderWalletPromise(userId,addressId,date,note,couponCode){
    return this.http.post(this.baseUrl + 'createOrderWallet', {userId: userId,addressId:addressId,
                                                         date:date,note:note,couponCode:couponCode}).toPromise();
   }

   changeMessage(message: string) {
    this.messageSource.next(message)
  }

 
   VerifyCoupon(coupon,userId, subTotal){
       
       return this.http.post(this.baseUrl + 'getCoupon', {couponCode:coupon}).subscribe((data:any) => {
    
        this.changeMessage("Cupon verificado")
        if(data.length != 0){
      
            let cuponID = data[0].id
            let cuponPrice = data[0].price
            let cuponActive = data[0].active;
          
            if(cuponActive == true){
            
                if(cuponPrice <= subTotal){
                    this.http.post(this.baseUrl + 'getUserCoupon', {userId:userId,couponId: cuponID}).subscribe((getCoupon:any) => {
                        if(getCoupon.length != 1){
                            this.changeMessage("Se aplico el cupon a su orden!");
                            
                        }else{

                            this.changeMessage("El cupon ya ha sido utilizado");
                    
                        }
                        
                    })
                }else{
                    this.changeMessage("Debe tener una compra mayor");
                      
                }
                
            }else{
                this.changeMessage("Este cupon no esta activo");
                
            }
            
        }else{
            this.changeMessage("El cupon no existe");
            
        }
           
       })
   }

   resetPasswordWithToken(token, pass) {
    return this.http.post(this.baseUrl + 'updatePassword', {
      resetToken: token,
      password: pass,
    });
  }

  contact(email, subject, message) {
    return this.http.post(this.baseUrl + 'contact', {
      email,
      subject,
      message,
    });
  }

  
  getFacebookDataDeletion(id) {
    //id is the confirmation code
    return this.http.post(this.baseUrl + 'getFacebookDataDeletion', { id: id });
  }

  
}

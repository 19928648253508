<nz-layout>
  <nz-layout class="app-layout">
    <nz-sider
    *ngIf="router.isActive('/welcome', true)"
      class="menu-sidebar"
      nzCollapsible
      nzWidth="256px"
      nzBreakpoint="md"
      [(nzCollapsed)]="isCollapsed"
      [nzTrigger]="null"
      [nzCollapsedWidth]="0"
    >
      <div class="sidebar-logo">
        <!-- <a href="https://ng.ant.design/" target="_blank">
********<nz-layout class="app-layout">
  <nz-sider
    *ngIf="router.url !== '/profile'"
    class="menu-sidebar"
    nzCollapsible
    nzWidth="256px"
    nzBreakpoint="md"
    [(nzCollapsed)]="isCollapsed"
    [nzTrigger]="null"
  >
    <div class="sidebar-logo">
      <!-- <a href="https://ng.ant.design/" target="_blank">
        <img src="https://ng.ant.design/assets/img/logo.svg" alt="logo" />
        <h1>Ant Design Of Angular</h1>
      </a> *********-->
      </div>
      <ul nz-menu nzTheme="light" nzMode="inline">
        <!-- [nzInlineCollapsed]="isCollapsed" -->
        <ul nz-menu-item (click)="loadProducts()" nzSelected>
          <!-- [nzTitle]="isCollapsed ? 'Home' : ''" -->
          <img
            [ngClass]="isCollapsed ? 'img-cat-col' : 'img-cat'"
            src="../assets/home.png"
            alt=""
          />
          <i style="opacity: 0;" nz-icon nzType="home"></i>
          <span>Home</span>
        </ul>
        <ul *ngFor="let cat of categories">
          <img
            [ngClass]="isCollapsed ? 'img-cat-col' : 'img-cat'"
            [src]="cat.imageUrl"
            alt=""
          />
          <li
            [ngClass]="isCollapsed ? 'ocult' : ''"
            nz-submenu
            [nzOpen]="false"
            [nzTitle]="cat.name"
            nzIcon="dashboard"
          >
            <ul>
              <li
                *ngFor="let sub of cat.subCategories"
                nz-menu-item
                (click)="productBySubcat(sub.name)"
              >
                {{ sub.name }}
              </li>
            </ul>
          </li>
        </ul>
      </ul>
    </nz-sider>
    <nz-layout>
      <nz-header>
        <div class="app-header">
          <div class="header-logo">
            <span
            *ngIf="router.isActive('/welcome', true)"
              class="header-trigger"
              class="header-trigger"
              (click)="toggleMenu()"
            >
              <i
                class="trigger"
                nz-icon
                [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
              ></i>
            </span>
            <a
              *ngIf="router.url === '/profile'"
              [routerLink]="['/welcome']"
              routerLinkActive="router-link-active"
              class="header-trigger hidden-mobile"
              >Volver a la tienda</a
            >
            <a
              *ngIf="router.url === '/profile'"
              [routerLink]="['/welcome']"
              routerLinkActive="router-link-active"
              class="header-trigger hidden-desktop"
              style="color: black;"
              ><i nz-icon nzType="arrow-left" nzTheme="outline"></i>
            </a>

            <img
              class="logo"
              style="width: 160px;"
              src="../assets/logo.png"
              alt=""
            />
            <div class="login-section" *ngIf="router.isActive('/welcome', true) || router.isActive('/profile', true)">
              <div class="row">
                <div class="ammount-items" *ngIf="userLogged === true">
                  <div>{{ CartLength }}</div>
                </div>
                <a>
                  <i
                    class="shopping-cart-icon"
                    (click)="showCartModal()"
                    nz-icon
                    nzType="shopping-cart"
                    nzTheme="outline"
                  ></i
                ></a>
                <p *ngIf="userLogged === false" class="hidden-mobile">
                  <a class="login" (click)="showLoginModal()"
                    >Login or Register</a
                  >
                </p>
                <a
                  (click)="showLoginModal()"
                  *ngIf="userLogged === false"
                  class="hidden-desktop"
                >
                  <i
                    class="shopping-cart-icon"
                    style="border: none;"
                    nz-icon
                    nzType="login"
                    nzTheme="outline"
                  ></i
                ></a>
                <a nz-dropdown [nzDropdownMenu]="menu" nzTrigger="click">
                  <i
                    *ngIf="userLogged === true"
                    class="shopping-cart-icon"
                    nz-icon
                    nzType="caret-down"
                    nzTheme="fill"
                  ></i>
                </a>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                  <ul nz-menu nzSelectable>
                    <li
                      routerLink="/profile"
                      routerLinkActive="router-link-active"
                      nz-menu-item
                    >
                      Perfil
                    </li>
                    <li (click)="logOut()" nz-menu-item>Cerrar sesión</li>
                  </ul>
                </nz-dropdown-menu>
              </div>
            </div>
          </div>
        </div>
      </nz-header>
      <nz-content>
        <router-outlet></router-outlet>
        <div
        *ngIf="router.isActive('/welcome', true)"
          class="inner-content"
          style="border-color: black;"
        >
          <div
            id="search-container"
            style="
              width: 100%;
              display: flex;
              height: 8%;
              align-items: center;
              justify-content: center;
            "
          >
            <nz-input-group
              [nzSuffix]="suffixIconSearch"
              style="
                width: 70%;
                height: 40px;
                display: flex;
                align-items: center;
                margin: 30px 0;
              "
            >
              <input
                [(ngModel)]="search"
                type="text"
                (keyup)="filterLocal()"
                nz-input
                placeholder="Buscar productos..."
              />
            </nz-input-group>
            <ng-template #suffixIconSearch>
              <i nz-icon nzType="search"></i>
            </ng-template>
          </div>
          <!-- <input
          type="text"
          style="border: 1px solid #d9d9d9; border-radius: 4px;"
          [(ngModel)]="search"
        /><i
          nz-icon
          nzType="search"
          nzTheme="outline"
          (click)="filterLocal()"
        ></i> -->
          <!-- <nz-list [nzLoading]="products.length == 0" [nzSize]="'small'">
          <nz-card
            *ngFor="let prod of products"
            nzXs="24"
            nzSm="24"
            nzMd="24"
            nzLg="12"
            nzXl="8"
            [nzTitle]="prod.name"
          >
            Card content
          </nz-card> </nz-list
        >` -->

          <!-- Carousel Banners / Ads -->
          <!-- <carousel *ngIf="ads.length !== 0" #myCarousel="carousel" id="carousel" [cellsToShow]="1" [witdh]="carouselWidth" [height]="carouselHeight" [cellWidth]="cellWidth" [autoplay]="true" [loop]="true" [arrows]="false">
            <div class="carousel-cell" *ngFor="let ad of ads">
              <img (click)="adClick(ad)" src="{{ (ad.get('media') != null) ? ad.get('media').url() : 'no-url-what'}}" style="width: 100%; height: auto;">
            </div>
          </carousel> -->
          <div nz-row style="height: 12vw;" *ngIf="ads != undefined && ads != ''">
            <div nz-col [nzSpan]="24">
              <nz-carousel nzAutoPlay nz-carousel-dots nzDotPosition="bottom" class="carousel">
                <div nz-carousel-content *ngFor="let ad of ads">
                  <img class="carousel-img" (click)="adClick(ad)" src="{{ (ad.get('media') != null) ? ad.get('media').url() : 'no-url-what'}}">
                </div>
              </nz-carousel>
              <!-- <div nz-carousel-dots *ngFor="let index of ads">{{index}}</div> -->
            </div>
          </div>

          <div
            *ngIf="products?.length == 0"
            style="width: 100%; display: flex; justify-content: center;"
          >
            <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
          </div>

          <div style="overflow: hidden; height: 88%;">
            <div nz-row [nzGutter]="[8, 8]">
              <div
                nz-col
                nzXs="24"
                nzSm="24"
                nzMd="24"
                nzLg="12"
                nzXl="8"
                [nzSpan]="8"
                *ngFor="
                  let prod of products
                    | paginate: { itemsPerPage: 12, currentPage: p };
                  let i = index
                "
              >
                <nz-card style="height: 350px; position: relative;">
                  <div
                    style="
                      width: 100%;
                      height: 100%;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <div
                      style="
                        height: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <img
                        *ngFor="let imgs of prod.images"
                        style="width: 35%;"
                        [src]="imgs.url"
                        alt=""
                      />
                    </div>
                    <div
                      class="details"
                      style="
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <h4>{{ prod.name }}</h4>
                      <p style="position: absolute; top: 20px; right: 10%;">
                        ${{ prod.price }}
                      </p>
                    </div>
                  </div>

                  <button
                    class="btn-add"
                    [nzLoading]="prod.selected"
                    nz-button
                    nzType="default"
                    (click)="AddCart(prod.id, i)"
                    disabled
                    *ngIf="!prod.inStock"
                  >
                    Out of Stock<i
                      nz-icon
                      nzType="shopping-cart"
                      nzTheme="outline"
                    ></i>
                  </button>
                  <!---Si el stock es TRUE-->
                  <button
                  class="btn-add"
                  [nzLoading]="prod.selected"
                  nz-button
                  nzType="default"
                  (click)="AddCart(prod.id, i)"
                  
                  *ngIf="prod.inStock"
                >
                  Añadir<i
                    nz-icon
                    nzType="shopping-cart"
                    nzTheme="outline"
                  ></i>
                </button>
                </nz-card>
              </div>
            </div>
          </div>
          <div class="pagination-force">
            <pagination-controls
              previousLabel=""
              nextLabel=""
              maxSize="12"
              responsive="true"
              (pageChange)="changePage($event)"
            ></pagination-controls>
          </div>
          <!-- <div style="width: 20%;" *ngFor="let prod of products">
          <div
            *ngFor="let imgs of prod.images"
            style="width: 150px; margin: 0px auto;"
          >
            <img src="{{ imgs.url }}" alt="" />
          </div>
          <div>{{ prod.name }}</div>
          <div>{{ prod.price }}</div>

          <nz-content class="add-cart">
            Añadir
          </nz-content>
        </div> -->
        </div>
      </nz-content>
      <nz-content> </nz-content>
    </nz-layout>
  </nz-layout>
  <nz-layout class="content-info" *ngIf="router.isActive('/welcome', true) || router.isActive('/profile', true)">
    <div
      style="
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
      "
    >
      <div style="width: 75%; padding-top: 3%;">
        <h1 style="color: #fff; font-weight: lighter; text-align: center;">
          Ronpon te entrega lo que necesites en menos de 
          <span style="font-weight: normal;">
            60
          </span>
          minutos. ¡Somos tu súper online!
        </h1>
      </div>
      <div style="width: 75%;">
        <h2 style="color: #fff; text-align: center;">
          Ordena lo que quieras de forma fácil, rápida y conveniente, no
          requerimos mínimo de compra. Actualmente estamos disponibles en: San
          Juan, Guaynabo, Isla Verde, Trujillo Alto, Bayamón y Carolina.
        </h2>
      </div>
    </div>
    <div
      nz-row
      [nzGutter]="[8, 8]"
      style="padding-bottom: 20px; padding-top: 50px !important;"
    >
      <div
        nz-col
        class="p-tb-30 ant-col-xs-24 ant-col-sm-8"
        nzSpan="8"
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <img style="height: 120px;" src="../assets/productos.png" alt="" />
        <p style="font-size: 21px; color: #fff;" class="textos">
          Escoge tus productos favoritos.
        </p>
      </div>
      <div
        nz-col
        class="p-tb-30 ant-col-xs-24 ant-col-sm-8"
        nzSpan="8"
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <img style="height: 110px;" src="../assets/dos.png" alt="" />
        <p
          style="
            font-size: 21px;
            color: #fff;
            margin-top: 55px;
            margin-bottom: 35px;
          "
        >
          Paga online.
        </p>
      </div>
      <div
        nz-col
        class="p-tb-30 ant-col-xs-24 ant-col-sm-8"
        nzSpan="8"
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <img style="height: 150px;" src="../assets/tres.png" alt="" />
        <p style="font-size: 21px; color: #fff;" class="textos">
          Recibe tu orden al momento.
        </p>
      </div>
    </div>
  </nz-layout>
  <nz-layout class="content-infoT" *ngIf="router.isActive('/welcome', true) || router.isActive('/profile', true)">
    <div
      style="
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
      "
    >
      <div style="width: 75%; padding-top: 3%;">
        <h1 style="color: #000; text-align: center;">
          ¡Descarga nuestra aplicación!
        </h1>
      </div>
      <div style="width: 75%; padding-top: 1.2%;">
        <h1 style="color: #000; text-align: center;">
          <a href="https://play.google.com/store/apps/details?id=com.ronpon" target="_blank">
            <img style="width: 150px;" src="../assets/android.png" alt="" />
          </a>
        </h1>
      </div>
      <div style="width: 75%;">
        <h1 style="color: #000; text-align: center;">
          <a href="https://itunes.apple.com/us/app/ronpon/id1322038716?mt=8" target="_blank">
            <img style="width: 150px;" src="../assets/apple.png" alt="" />
          </a>
        </h1>
      </div>
      <div style="width: 75%; padding-top: 1%;">
        <h1 style="color: #000; text-align: center;">
          Horario de Entregas
        </h1>
      </div>
      <div style="width: 75%; padding-top: 1%;">
        <div nz-row>
          <div nz-col nzSpan="8">
            <p style="color: #000; text-align: center;"><b>Dia</b></p>
          </div>
          <div nz-col nzSpan="8">
            <p style="color: #000; text-align: center;"><b>Desde</b></p>
          </div>
          <div nz-col nzSpan="8">
            <p style="color: #000; text-align: center;"><b>Hasta</b></p>
          </div>
        </div>
        <div nz-row *ngFor="let date of schedule">
          <div nz-col nzSpan="8">
            <p style="color: #000; text-align: center; margin: 0;">
              {{ date.day }}
            </p>
          </div>
          <div nz-col nzSpan="8">
            <p style="color: #000; text-align: center; margin: 0;">
              {{ date.init }}
            </p>
          </div>
          <div nz-col nzSpan="8">
            <p style="color: #000; text-align: center; margin: 0;">
              {{ date.end }}
            </p>
          </div>
        </div>
      </div>
      <div style="width: 75%; padding-top: 1%;">
        <p style="text-align: center;">
          Teléfono: +1 (787) 223-6237
        </p>
      </div>
      <div style="text-align: center; margin-top: 20px;">
        <p><strong>Otros Servicios:</strong></p>
        <a href="https://www.ronponentregaspr.com" target="_blank">
          <img src="../assets/ronpon-entregas.png" style="width: 100%; height: auto; max-width: 200px;">
        </a>
      </div>
      <div style="width: 75%; padding-top: 4%;">
        <p style="text-align: center;">
          RONPONPR.COM © {{(browserYear && browserYear.getFullYear) ? browserYear.getFullYear() : ''}}
        </p>
      </div>
      <div
        nz-row
        [nzGutter]="[8, 8]"
        style="padding-bottom: 10px; width: 100%;"
      >
        <div
          nz-col
          class="p-tb-30 ant-col-xs-24 ant-col-sm-8"
          nzSpan="8"
          style="display: flex; justify-content: center; align-items: center;"
        >
         <a href="https://www.facebook.com/RonponPR" target="_blank"> <img style="height: 38px;" src="../assets/fb.png" alt="" /></a>
        <a href="https://www.instagram.com/ronponpr/?hl=en" target="_blank"> <img
            style="height: 38px; margin-left: 16px;"
            src="../assets/instagram.png"
            alt=""
          /></a>
        </div>
        <div
          nz-col
          class="p-tb-30 ant-col-xs-24 ant-col-sm-8"
          nzSpan="8"
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          "
        >
          <p>
            <small>
              Partner with
            </small>
          </p>
          <img
            style="height: 60px;"
            src="../assets/logo-plaza-loiza-2021.jpg"
            alt=""
          />
        </div>
        <div
          nz-col
          class="p-tb-30 ant-col-xs-24 ant-col-sm-8"
          nzSpan="8"
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          "
        >
        <p class="textos">
            <a [routerLink]="['/terms-privacy']" routerLinkActive="router-link-active" target="_blank">Términos de Uso & Privacidad
            </a> |
            <a (click)="showContactModal()">Contáctanos</a>
        </p>
        </div>
      </div>
    </div>
  </nz-layout>
</nz-layout>

<!-- Login Modal -->
<nz-modal
  [(nzVisible)]="loginVisible"
  nzTitle="Conéctate"
  (nzOnCancel)="closeLoginModals()"
  class="loginModal"
  [nzFooter]="null"
>
  <form
    nz-form
    [formGroup]="loginForm"
    class="login-form"
    (ngSubmit)="submitLoginForm()"
  >
    <nz-form-item>
      <nz-form-control nzErrorTip="Por favor ingrese su correo electrónico!">
        <nz-input-group nzPrefixIcon="mail" class="login-input">
          <input
            type="text"
            nz-input
            formControlName="userName"
            placeholder="Correo Electrónico"
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control nzErrorTip="Por favor ingrese su contraseña!">
        <nz-input-group nzPrefixIcon="lock" class="login-input">
          <input
            type="password"
            nz-input
            formControlName="password"
            placeholder="Contraseña"
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <div nz-row class="login-form-margin">
      <!-- <div nz-col [nzSpan]="12">
        <label nz-checkbox formControlName="remember">
          <span>Remember me</span>
        </label>
      </div> -->
      <!--       <div nz-col [nzSpan]="12">
        class="login-form-forgot"
      </div> -->
    </div>
    <button
      nz-button
      class="login-form-button login-form-margin"
      [nzType]="'primary'"
    >
      Log in
    </button>
    <div class="login-extra">
      <a (click)="showForgotModal()">¿Olvidó su contraseña?</a> o
      <a (click)="showRegisterModal()"> regístrate </a>
    </div>
  </form>
</nz-modal>

<!-- Register Modal -->
<nz-modal
  [(nzVisible)]="registerVisible"
  nzTitle="Regístrate"
  (nzOnCancel)="closeLoginModals()"
  class="loginModal"
  [nzFooter]="null"
>
  <form nz-form [formGroup]="registerForm" (ngSubmit)="submitRegisterForm()">
    <nz-form-item>
      <nz-form-label [nzSm]="10" [nzXs]="24" nzRequired nzFor="email"
        >Correo Electrónico</nz-form-label
      >
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        nzErrorTip="El correo electrónico no es valido!"
      >
        <input
          class="register-input"
          nz-input
          formControlName="email"
          id="email"
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="registerPassword" nzRequired
        >Contraseña</nz-form-label
      >
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        nzErrorTip="Por favor ingrese su contraseña"
      >
        <input
          class="register-input"
          nz-input
          type="password"
          id="registerPassword"
          formControlName="registerPassword"
          (ngModelChange)="updateConfirmValidator()"
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label
        [nzSm]="10"
        [nzXs]="24"
        nzFor="checkRegisterPassword"
        nzRequired
        >Confirme su contraseña</nz-form-label
      >
      <nz-form-control [nzSm]="14" [nzXs]="24" [nzErrorTip]="errorTpl">
        <input
          nz-input
          type="password"
          formControlName="checkRegisterPassword"
          id="checkPassword"
        />
        <ng-template #errorTpl let-control>
          <ng-container *ngIf="control.hasError('required')">
            Por favor confirme su contraseña!
          </ng-container>
          <ng-container *ngIf="control.hasError('confirm')">
            Las contraseñas no coinciden!
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="name" nzRequired>
        Nombre
      </nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        nzErrorTip="Por favor digite su nombre!"
      >
        <input
          class="register-input"
          nz-input
          id="name"
          formControlName="name"
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="phoneNumber" nzRequired
        >Teléfono</nz-form-label
      >
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        [nzValidateStatus]="registerForm.controls['phoneNumber']"
        nzErrorTip="Por favor ingrese su teléfono!"
      >
        <input
          class="register-input"
          nz-input
          formControlName="phoneNumber"
          id="phoneNumber"
          nz-input
        />
      </nz-form-control>
    </nz-form-item>
    <!--   <nz-form-item>
    <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="website" nzRequired>Website</nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Please input website!">
      <input nz-input id="website" formControlName="website" placeholder="website" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="captcha" nzRequired>Captcha</nz-form-label>
    <nz-form-control
      [nzSm]="14"
      [nzXs]="24"
      nzErrorTip="Please input the captcha you got!"
      nzExtra="We must make sure that your are a human."
    >
      <div nz-row [nzGutter]="8">
        <div nz-col [nzSpan]="12">
          <input nz-input formControlName="captcha" id="captcha" />
        </div>
        <div nz-col [nzSpan]="12">
          <button nz-button (click)="getCaptcha($event)">Get captcha</button>
        </div>
      </div>
    </nz-form-control>
  </nz-form-item> -->
    <nz-form-item nz-row class="register-area">
      <nz-form-control [nzSpan]="30">
        <span>
          Al presionar el botón de "Registrar" estaría aceptando los
          <a>Términos de Uso y Política de Privacidad</a> de Ronpon.
        </span>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item nz-row class="register-area">
      <nz-form-control [nzSpan]="16" [nzOffset]="4">
        <button
          nz-button
          class="login-form-button login-form-margin"
          [nzType]="'primary'"
        >
          Registrar
        </button>
        <div class="login-extra">
          o <a (click)="showLoginModal()"> conéctate </a>
        </div>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-modal>

<!-- Forgot Password Modal -->
<nz-modal
  [(nzVisible)]="forgotVisible"
  nzTitle="Conéctate"
  (nzOnCancel)="closeLoginModals()"
  class="loginModal"
  [nzFooter]="null"
>
  <form
    nz-form
    [formGroup]="forgotForm"
    class="login-form"
    (ngSubmit)="submitForgotForm()"
  >
    <nz-form-item>
      <nz-form-control nzErrorTip="Por favor ingrese su correo electrónico!">
        <nz-input-group nzPrefixIcon="mail" class="login-input">
          <input
            type="text"
            nz-input
            formControlName="forgotEmail"
            placeholder="Correo Electrónico"
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <button
      nz-button
      class="login-form-button login-form-margin"
      [nzType]="'primary'"
    >
      Enviar
    </button>
    <div class="login-extra">
      O <a (click)="showLoginModal()">conéctate </a>
    </div>
  </form>
</nz-modal>

<!--Cart Modal-->
<nz-modal
  [(nzVisible)]="cartVisible"
  nzTitle="Carrito"
  (nzOnCancel)="cartCancel()"
  class="cartModal"
  [nzFooter]="null"
>
<nz-spin [nzSpinning]="isSpinning">
  <nz-list
    nzItemLayout="horizontal"
    style="height: 250px; overflow-y: auto;"
    *ngIf="!Checkout && !creditCards && !note && !delivery && !AddCard"
  >
    <nz-list-item *ngFor="let item of CartData.products">
      <nz-list-item-meta [nzAvatar]="(item.images != null && item.images.length != 0) ? item.images[0].url : ''">
        <nz-list-item-meta-title>
          <div nz-row>
            <div nz-col nzSpan="12" style="padding: 0 10px;">
              <a>{{ item.name }}</a>
              <div>Precio: {{ item.price }}</div>
            </div>
            <div 
            nz-col
            nzSpan="6"
            [ngClass]="{avaiableRed: item.available == 'No disponible'}"
            style="
            display: flex;
            justify-content: center;
            align-items: flex-start;
          ">
              {{ item.available}}
            </div>
            <div
              nz-col
              nzSpan="4"
              style="
                display: flex;
                justify-content: center;
                align-items: flex-start;
              "
            >
              <input
                style="
                  width: 100%;
                  border: 1px solid #d4d4d4;
                  border-radius: 5px;
                "
                (change)="UpdateCart(item.id, $event)"
                type="number"
                value="{{ item.quantity }}"
                min="1"
              />
            </div>
            <div
              nz-col
              nzSpan="2"
              style="
                display: flex;
                justify-content: center;
                align-items: flex-start;
              "
            >
              <button
                nz-button
                nzType="primary"
                [nzSize]="'small'"
                nzShape="circle"
                (click)="DeleteCart(item.id)"
              >
                <i nz-icon nzType="close" nzTheme="outline"></i>
              </button>
            </div>
          </div>
        </nz-list-item-meta-title>
      </nz-list-item-meta>
    </nz-list-item>
    <!-- <nz-list-empty *ngIf="CartData.products.length === 0"></nz-list-empty> -->
  </nz-list>
  <nz-list
    *ngIf="!Checkout && !creditCards && !note && !delivery && !AddCard"
    style="border: 1px solid #f0f0f0; border-radius: 5px; margin-bottom: 20px;"
  >
    <nz-list-item style="padding: 12px 20px;">
      <nz-list-item-meta>
        <nz-list-item-meta-title>
          <strong>SubTotal:</strong> {{ CartData.subTotal }}
        </nz-list-item-meta-title>
      </nz-list-item-meta>
    </nz-list-item>
    <nz-list-item style="padding: 12px 20px;">
      <nz-list-item-meta>
        <nz-list-item-meta-title>
          <strong>Delivery Fee:</strong> {{ CartData.fee }}
        </nz-list-item-meta-title>
      </nz-list-item-meta>
    </nz-list-item>
    <nz-list-item style="padding: 12px 20px;">
      <nz-list-item-meta>
        <nz-list-item-meta-title>
          <strong>Tax:</strong>{{ CartData.tax }}
        </nz-list-item-meta-title>
      </nz-list-item-meta>
    </nz-list-item>
    <nz-list-item style="padding: 12px 20px;">
      <nz-list-item-meta>
        <nz-list-item-meta-title>
          <strong>Total:</strong> {{ CartData.total | number : '1.2-2' }}
        </nz-list-item-meta-title>
      </nz-list-item-meta>
    </nz-list-item>
  </nz-list>
  <div  *ngIf="!Checkout && !creditCards && !note && !delivery && !AddCard">
    <strong>Enter your coupon code:</strong>
    <input nz-input type="text" style="margin-bottom: 10px;" placeholder="Coupon code" [(ngModel)]="couponCode" (keyup.enter)="checkCoupon()">
    
    <button
      nz-button
      style="background: rgb(17, 139, 181); color: #fff; border-radius: 5px;margin-bottom: 15px;"
      (click)="checkCoupon()"
    >
      Validate
    </button>
    {{message}}
    <hr>
</div>
  <!-- <div *ngIf="!Checkout && !creditCards && !note && !delivery && !AddCard">
    <nz-form-item *ngFor="let cart of CartData.products">
      <div>
        <div *ngFor="let img of cart.images">
          <img
            src="{{ img.url }}"
            alt=""
            style="border-radius: 50%; height: 30px;"
          />
        </div>
        <div>{{ cart.name }}</div>
      </div>

      <hr />
    </nz-form-item>
    <div class="TotalFee" style="margin-bottom: 10px;">
      <strong>SubTotal:</strong> {{ CartData.subTotal }}
      <strong>Delivery Fee:</strong> {{ CartData.fee }} <strong>Tax:</strong>
      {{ CartData.tax }} <strong>Total:</strong> {{ CartData.total }}
    </div>
    <div>
        <strong>Enter your coupon code:</strong>
        <input type="text" value="Coupon code" [(ngModel)]="couponCode" (keyup)="checkCoupon()">
    </div>
  </div> -->

  <!-- <button
    nz-button
    class="login-form-button login-form-margin"
    [nzType]="'primary'">
    Checkout
    </button> -->
  <a
    nz-button
    *ngIf="
      !Checkout &&
      !creditCards &&
      !note &&
      !delivery &&
      !AddCard &&
      CartData.products?.length > 0
    "
    [nzType]="'primary'"
    (click)="Direction()"
    [disabled]="disableCheckout"
  >
    Checkout
  </a>
  <h5 *ngIf="disableCheckout" class="avaiableRed">Remover los articulos no disponibles para continuar</h5>
 

  <div *ngIf="Checkout" class="direccion">
    <button
      nz-button
      style="background: rgb(17, 139, 181); color: #fff; border-radius: 5px;"
      (click)="CreateAddress()"
    >
      + Crear nueva dirección
    </button>
    <div *ngIf="createAddress" class="tableDir">
      <form [formGroup]="AddressForm" (ngSubmit)="submitAddress()">
        <div nz-row [nzGutter]="[8, 8]" style="padding: 10px 0;">
          <div nz-col nzSpan="12">
            <input
              nz-input
              placeholder="Street address"
              type="text"
              id="Sa1"
              value="Street address"
              formControlName="StreetAdd1"
            />
          </div>
          <div nz-col nzSpan="12">
            <input
              nz-input
              placeholder="Street address 2"
              type="text"
              id="Sa2"
              value="Street address 2"
              formControlName="StreetAdd2"
            />
          </div>
          <div nz-col nzSpan="12">
            <input
              nz-input
              placeholder="Ciudad"
              type="text"
              id="Ciudad"
              value="Ciudad"
              formControlName="Ciudad"
            />
          </div>
          <div nz-col nzSpan="12">
            <nz-select
              style="width: 100%;"
              formControlName="ZipCode"
              nzPlaceholder="ZipCode"
              [(ngModel)]="selectedZip"
            >
              <nz-option
                *ngFor="let zip of zipCodeArr"
                nzLabel="{{ zip }}"
                nzValue="{{ zip }}"
              ></nz-option>
            </nz-select>
            <!-- <input
              nz-input
              placeholder="ZipCode"
              type="email"
              id="ZipCode"
              value="ZipCode"
              formControlName="ZipCode"
            /> -->
          </div>
        </div>

        <button nz-button (click)="closeCreateAddress()">
          Cancel
        </button>
        <button
          nz-button
          type="submit"
          style="
            background: rgb(17, 139, 181);
            color: #fff;
            border-radius: 5px;
            margin-left: 8px;
          "
        >
          <!-- (click)="submitAddress()" -->
          Guardar dirección
        </button>
        <!-- <input nz-button  value="Guardar dirección" /> -->
      </form>
    </div>
    <nz-list
      style="
        border: 1px solid #f0f0f0;
        border-radius: 5px;
        margin-bottom: 20px;
        margin-top: 20px;
      "
    >
      <nz-list-item style="padding: 12px 20px;">
        <nz-list-item-meta>
          <nz-list-item-meta-title>
            <strong>Seleccione a que dirección enviar</strong>
          </nz-list-item-meta-title>
        </nz-list-item-meta>
      </nz-list-item>
      <nz-list-item
        style="padding: 12px 20px;"
        *ngFor="let adds of AddressData"
      >
        <nz-list-item-meta>
          <nz-list-item-meta-title>
            <div nz-row>
              <div nz-col nzXs="24" nzSm="24" nzMd="18" nzLg="18" nzXl="18">
                Address: {{ adds.streetAddress1 }} Address 2:
                {{ adds.streetAddress2 }} City: {{ adds.city }} State:
                {{ adds.state }} ZipCode: {{ adds.zipCode.zipCode }}
              </div>
              <div nz-col nzXs="4" nzSm="4" nzMd="4" nzLg="4" nzXl="4">
                <button
                  style="
                    background: rgb(17, 139, 181);
                    color: #fff;
                    border-radius: 5px;
                  "
                  nz-button
                  (click)="selectAddress(adds.id)"
                >
                  Seleccionar
                </button>
              </div>
            </div>
          </nz-list-item-meta-title>
        </nz-list-item-meta>
      </nz-list-item>
    </nz-list>
    <div>
      <button
        style="background: rgb(17, 139, 181); color: #fff; border-radius: 5px;"
        nz-button
        (click)="AddressBack()"
      >
        Volver
      </button>
    </div>

    <!-- <div class="table">
      <nz-table>
        <thead>
          <tr>
            <td>Seleccione a que dirección enviar</td>
          </tr>
        </thead>
        <tbody *ngFor="let adds of AddressData">
          <tr>
            <td>
              Address: {{ adds.streetAddress1 }} Address 2:
              {{ adds.streetAddress2 }} City: {{ adds.city }} State:
              {{ adds.state }} ZipCode: {{ adds.zipCode.zipCode }}
              <button (click)="selectAddress(adds.id)">
                Seleccionar
              </button>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div> -->
  </div>

  <div *ngIf="creditCards">
    <div class="cardTable">
      <button
        nz-button
        style="background: rgb(17, 139, 181); color: #fff; border-radius: 5px;"
        (click)="CreateCard()"
      >
        + Crear nueva tarjeta de credito
      </button>
      <!-- <input
        type="button"
        tyle="background: rgb(17, 139, 181); color: #fff; border-radius: 5px;"
        value="+ Crear nueva tarjeta de credito"
        (click)="CreateCard()"
      /> -->
      <div *ngIf="AddCard">
        <div nz-row [nzGutter]="[8, 8]" style="padding: 10px 0;">
          <div nz-col nzSpan="12">
            <input
              type="text"
              nz-input
              placeholder="Cardholder Name"
              value="Cardholder Name"
              [(ngModel)]="holderName"
            />
          </div>
          <div nz-col nzSpan="12">
            <input
              nz-input
              placeholder="Exp. Date (MMYY)"
              type="text"
              value="Expiration Date (MMYY)"
              [(ngModel)]="expDate"
            />
          </div>
          <div nz-col nzSpan="12">
            <input
              nz-input
              placeholder="Card Number"
              type="text"
              value="Card Number"
              [(ngModel)]="cardNumber"
            />
          </div>
          <div nz-col nzSpan="12">
            <input
              nz-input
              placeholder="CVC"
              type="text"
              value="CVC"
              [(ngModel)]="cvv"
            />
          </div>
          <div nz-col nzSpan="12">
            <input
              type="text"
              nz-input
              placeholder="Email"
              value=""
              [(ngModel)]="cardEmail"
            />
          </div>
          <div nz-col nzSpan="12">
            <input
              nz-input
              placeholder="Zipcode"
              type="text"
              value=""
              [(ngModel)]="cardZipcode"
            />
          </div>
          <div nz-col nzSpan="12">
            <nz-select
              style="width: 100%;"
              nzPlaceHolder="Seleccione una tarjeta"
              [(ngModel)]="SelectedCard"
            >
              <nz-option
                *ngFor="let cards of CardTypes"
                nzValue="{{ cards }}"
                nzLabel="{{ cards }}"
                >{{ cards }}</nz-option
              >
            </nz-select>
          </div>
        </div>
        <div class="CardContainer">
          <div>
            <!-- <input type="text" value="Card holder Name"> -->
          </div>
          <div>
            <!-- <input type="button" value="Cancel" (click)="closeAddCard()" />
            <input
              type="button"
              value="Guardar Tarjeta de Credito"
              (click)="generateCard()"
            /> -->

            <button nz-button (click)="closeAddCard()">
              Cancel
            </button>
            <button
              nz-button
              type="submit"
              style="
                background: rgb(17, 139, 181);
                color: #fff;
                border-radius: 5px;
                margin-left: 8px;
              "
              (click)="saveEwallet()"
            >
            <!-- generateCard -->
              Guardar tarjeta
            </button>
          </div>
        </div>
      </div>
      <!-- <button
        nz-button
        style="background: rgb(17, 139, 181); color: #fff; border-radius: 5px;"
        (click)="CreateAddress()"
      >
        + Crear nueva dirección
      </button> -->
      <nz-list
        style="
          border: 1px solid #f0f0f0;
          border-radius: 5px;
          margin-bottom: 20px;
          margin-top: 20px;
        "
      >
        <nz-list-item style="padding: 12px 20px;">
          <nz-list-item-meta>
            <nz-list-item-meta-title>
              <strong>Seleccione tarjeta de crédito</strong>
            </nz-list-item-meta-title>
          </nz-list-item-meta>
        </nz-list-item>
        <nz-list-item
          style="padding: 12px 20px;"
          *ngFor="let cards of CardData"
        >
          <nz-list-item-meta>
            <nz-list-item-meta-title>
              <div nz-row>
                <div nz-col nzXs="24" nzSm="24" nzMd="18" nzLg="18" nzXl="18">
                  <strong> Type: </strong>{{ cards.type }}
                  <strong> Nombre:</strong> {{ cards.cardHolderName }}
                  <strong> Expira:</strong>{{ cards.expDate }}
                </div>
                <div nz-col nzXs="" nzSm="4" nzMd="4" nzLg="4" nzXl="4">
                  <button
                    style="
                      background: rgb(17, 139, 181);
                      color: #fff;
                      border-radius: 5px;
                    "
                    nz-button
                    (click)="selectCard(cards.id)"
                  >
                    Seleccionar
                  </button>
                </div>
                <div nz-col nzSpan="2"></div>
              </div>
            </nz-list-item-meta-title>
          </nz-list-item-meta>
        </nz-list-item>
      </nz-list>
      <div>
        <button
          style="
            background: rgb(17, 139, 181);
            color: #fff;
            border-radius: 5px;
          "
          nz-button
          (click)="CardBack()"
        >
          volver
        </button>
      </div>
      <!-- ----------------------------- -->
      <!-- ----------------------------- -->
      <!-- ----------------------------- -->

      <!-- <table>
        <thead>
          <tr>
            <td>Seleccione tarjeta de crédito</td>
          </tr>
        </thead>
        <tbody *ngFor="let cards of CardData">
          <tr>
            <td>
              <strong> Type: </strong>{{ cards.type }}
              <strong> Nombre:</strong> {{ cards.cardHolderName }}
              <strong> Expira:</strong>{{ cards.expDate }}
            </td>
            <button (click)="selectCard(cards.id)">
              Seleccionar
            </button>
          </tr>
        </tbody>
      </table> -->
    </div>
  </div>

  <div *ngIf="note">
    <div>
      <div nzSpan="24" style="margin-bottom: 10px;">
        <input
          nz-input
          [(ngModel)]="Note"
          type="text"
          placeholder="Nota para el conductor. (opcional)"
        />
      </div>

      <div class="buttons">
        <button nz-button (click)="noteBack()">
          Volver
        </button>

        <button
          nz-button
          style="
            background: rgb(17, 139, 181);
            color: #fff;
            border-radius: 5px;
            margin-left: 8px;
          "
          (click)="GoToPay()"
        >
          Continuar
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="delivery">
    <h3 style="width: 100%; text-align: center; margin-bottom: 15px;">
      Opciones de delivery
    </h3>
    <!-- <div id="WorkingTime"> </div> -->
    <!-- <input
      id="buyASAP"
      type="button"
      placeholder="ASAP (1hr o menos)"
      value="ASAP (1hr o menos)"
      (click)="BuyASAP()"
    /> -->
    <div *ngIf="checkAsapHour()"></div>

    <div *ngIf="DeliveryHr" style="text-align: center;">
      <p>
        ASAP option is not available since we are not within our delivery hours.
        You can schedule a delivery for your order.
      </p>

      <p>Delivery Hours:</p>
      
      <p>Monday and Tuesday: 10:00 AM - 10:00 PM</p>
      <p>Wednesday, Thursday and Friday:10:00 AM - 12:00 AM</p>

      <p>Saturday: 10:00 AM - 12:00 AM</p>
      <p>Sunday: 10:00 AM - 8:00 PM</p>
    </div>
    <button
      nz-button
      id="buyASAP"
      style="
        background: rgb(17, 139, 181);
        color: #fff;
        border-radius: 5px;
        margin: 0 auto;
        display: block;
        margin-bottom: 15px;
      "
      (click)="BuyASAP()"
    >
      ASAP (1hr o menos)
    </button>
    <p style="text-align: center;"><strong>o en:</strong></p>
    <!-- <nz-date-picker [(ngModel)]="dateSelect" (ngModelChange)="onChange($event)"></nz-date-picker> -->
    <div class="dates" style="margin: 15px; margin-left: 10%;" >
      Fecha y hora:<nz-date-picker
        style="display: inline-block; width: 50%; margin-right: 10px;"
        [nzShowTime]="{ nzFormat: 'h a', nzUse12Hours: true, nzDefaultOpenValue: timeDefaultValue }"
        nzFormat="yyyy-MM-dd hh:mm a"
        [(ngModel)]="dateSelect"
        (ngModelChange)="getZeroDate()"
        [nzDisabledDate]="disabledDate"
        [nzDisabledTime]="disabledDateTime"
        [nzSize]="'medium'"
        class="date-picker"
      ></nz-date-picker>

    <!--   Hora:
      <select style="display: inline-block; width: 30%; margin-right: 10px;padding: 4px 11px;border: 1px solid #d9d9d9;" (change)="enablePayment($event)"> -->
        
          <!-- <option value="1:00 AM - 2:00 AM">1:00 AM - 2:00 AM</option>
          <option value="2:00 AM - 3:00 AM">2:00 AM - 3:00 AM</option>
          <option value="3:00 AM - 4:00 AM">3:00 AM - 4:00 AM</option>
          <option value="4:00 AM - 5:00 AM">4:00 AM - 5:00 AM</option>
          <option value="5:00 AM - 6:00 AM">5:00 AM - 6:00 AM</option>
          <option value="6:00 AM - 7:00 AM">6:00 AM - 7:00 AM</option>
          <option value="7:00 AM - 8:00 AM">7:00 AM - 8:00 AM</option>
          <option value="8:00 AM - 9:00 AM">8:00 AM - 9:00 AM</option> -->
          <!-- <option value="9:00 AM - 10:00 AM">9:00 AM - 10:00 AM</option> -->
          <!-- <option>Seleccionar Hora</option>
          <option value="10:00 AM - 11:00 AM">10:00 AM - 11:00 AM</option>
          <option value="11:00 AM - 12:00 PM">11:00 AM - 12:00 PM</option>
          <option value="12:00 PM - 1:00 PM">12:00 PM - 1:00 PM</option>
          <option value="1:00 PM - 2:00 PM">1:00 PM - 2:00 PM</option>
          <option value="2:00 PM - 3:00 PM">2:00 PM - 3:00 PM</option>
          <option value="3:00 PM - 4:00 PM">3:00 PM - 4:00 PM</option>
          <option value="4:00 PM - 5:00 PM">4:00 PM - 5:00 PM</option>
          <option value="5:00 PM - 6:00 PM">5:00 PM - 6:00 PM</option>
          <option value="6:00 PM - 7:00 PM">6:00 PM - 7:00 PM</option>
          <option value="7:00 PM - 8:00 PM">7:00 PM - 8:00 PM</option>
          <option value="8:00 PM - 9:00 PM">8:00 PM - 9:00 PM</option>
          <option value="9:00 PM - 10:00 PM">9:00 PM - 10:00 PM</option>
          <option value="10:00 PM - 11:00 PM">10:00 PM - 11:00 PM</option>
          <option value="11:00 PM - 12:00 AM">11:00 PM - 12:00 AM</option> -->
          <!-- <option value="12:00 AM - 1:00 AM">12:00 AM - 1:00 AM</option> -->
      <!-- </select> -->
      <!-- <nz-time-picker
        nzFormat="h:mm"
        [nzDisabledHours]="disabledHours"
        [nzMinuteStep]="10"
        [nzHideDisabledOptions]="true"
        style="display: inline-block; width: 30%; margin-right: 10px;"
        [(ngModel)]="someTime"
      ></nz-time-picker> -->
    </div>

    <div
      style="
        border-top: 1px solid #e8e8e8;
        padding: 10px 16px;
        text-align: right;
        border-radius: 0 0 4px 4px;
      "
    >
      <button nz-button (click)="DeliveryOptions()">
        Volver
      </button>
      <button
      *ngIf="dateSelect || paymentTrue == true"
      id="generateOrder"
        nz-button
        style="
          background: rgb(17, 139, 181);
          color: #fff;
          border-radius: 5px;
          margin-left: 8px;
        "
        (click)="GenerateOrder2()"
        [nzLoading]="loadOrder"
      >
        Pagar y crear order
      </button>
      <button
      *ngIf="!dateSelect && paymentTrue == false"
      id="generateOrder"
        nz-button
        style="
          background: #cccccc;
          color: #666666;
          border-radius: 5px;
          margin-left: 8px;
          pointer-events: none;
        "
        (click)="GenerateOrder2()"
      >
        Pagar y crear order
      </button>
    </div>
  </div>
</nz-spin>
</nz-modal>

<nz-modal [(nzVisible)]="isVisible" nzTitle="Error al añadir" (nzOnOk)="handleOk()" (nzOnCancel)="cancelOK()" [nzCancelText]="null">
  <p>Favor hacer login o crear cuenta para añadir producto al carrito.</p>
  
</nz-modal>

<nz-modal [(nzVisible)]="isVisible2" (nzOnOk)="handleOk2()" (nzOnCancel)="handleOk2()" [nzCancelText]="null" [nzClosable]="false" nzOkText="Cerrar">
  <strong><p [innerHtml]="messageGenerateOrder" style="text-align: center;"></p></strong>
  
</nz-modal>

<!--<nz-modal [(nzVisible)]="isVisible3" [nzFooter]="null" (nzOnCancel)="handleOk3()" (nzOnOk)="handleOk3()">
      
  <i nz-icon nzType="info" nzTheme="outline" style="background:rgb(17, 139, 181);color: white;border-radius:50%;"></i>
  <p style="color: #0aceff;"><strong> Para mejor experiencia descargue la aplicación</strong></p>
  <div>
      <a href="https://play.google.com/store/apps/details?id=com.ronpon" target="_blank">
          <img src="../assets/android.png" style="width: 150px; height: auto; display: block; margin-left: auto; margin-right: auto; " alt="">
      </a>
      <br>
      <a href="https://itunes.apple.com/us/app/ronpon/id1322038716?mt=8" target="_blank">
        <img src="../assets/apple.png" style="width: 150px; height: auto; display: block; margin-left: auto; margin-right: auto; margin-top: 6px;" alt="">
    </a>
  </div>

  <p style="color: #233e86; text-align: center; margin-top: 24px;">
    ¡Somos tu súper online! <br>
    🚙 Entregamos en 60 minutos o menos. <br>
    💰 No requerimos mínimo de compra. <br>
    🛒 5,000+ productos disponibles.
  </p>

</nz-modal>-->

<nz-modal id="principal-modal" [(nzVisible)]="isVisible3" [nzFooter]="null" (nzOnCancel)="handleOk3()" (nzOnOk)="handleOk3()" >
      
  <i nz-icon nzType="info" nzTheme="outline" style="background:rgb(17, 139, 181);color: white;border-radius:50%;"></i>
  
  <img src="../assets/ronpon-redefinimos-convenencia-new.png" style="width: 100%; height: auto;"/>

  <div style="width: 100%; max-width: 500px; margin: 10px auto;" class="desktop">
    <div style="float: left;">
      <a href="https://play.google.com/store/apps/details?id=com.ronpon" target="_blank">
        <img src="../assets/android.png" style="height: auto; display: block; margin-left: auto; margin-right: auto; " class="image-size" alt="">
      </a>
    </div>
    <div style="float: right;">
      <a href="https://itunes.apple.com/us/app/ronpon/id1322038716?mt=8" target="_blank">
        <img src="../assets/apple.png" style="height: auto; display: block; margin-left: auto; margin-right: auto;" class="image-size" alt="">
      </a>
    </div>
  </div>

  <!-- <p style="color: #233e86; text-align: center; margin-top: 24px;">
    ¡Somos tu súper online! <br>
    🚙 Entregamos en 60 minutos o menos. <br>
    💰 No requerimos mínimo de compra. <br>
    🛒 5,000+ productos disponibles.
  </p> -->

  <br>
  <br>

</nz-modal>

  <!-- Contact Modal -->
<nz-modal
nzWidth="300px"
[(nzVisible)]="contactVisible"
nzTitle="Contáctanos"
(nzOnCancel)="closeContactModal()"
class="contact-modal"
[nzFooter]="null"
>
  <form
    nz-form
    [formGroup]="contactForm"
    class="login-form"
    (ngSubmit)="submitContactForm()"
  >
    <nz-form-item>
      <nz-form-control nzErrorTip="Por favor ingrese su correo electrónico!">
        <nz-input-group nzPrefixIcon="mail" class="login-input">
          <input
            type="text"
            nz-input
            formControlName="contactEmail"
            placeholder="Correo Electrónico"
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control nzErrorTip="Por favor ingrese un asunto">
        <nz-input-group class="login-input">
          <input
            type="text"
            nz-input
            formControlName="subject"
            placeholder="Asunto"
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control nzErrorTip="Por favor ingrese un mensaje">
        <nz-input-group class="login-input">
          <input
            type="text"
            nz-input
            formControlName="message"
            placeholder="Mensaje"
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <button
      nz-button
      class="login-form-button login-form-margin"
      [nzType]="'primary'"
    >
      Enviar
    </button>
    <p>
      Teléfono: +1 (787) 223-6237
    </p>
  </form>
</nz-modal>

<!-- Ads HTML goes here prraaa -->

<!-- <nz-modal nzTitle="Error de pago"
(nzOnCancel)="closeContactModal()" *ngIf="MessageBool">
    {{Message}}
</nz-modal> -->
<!-- <br>
<div *ngFor="let ad of ads">
  <img src="{{ (ad.get('media') != null) ? ad.get('media').url() : 'no-url-what'}}" style="width: 100%; height: auto;">
</div> -->